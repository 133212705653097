.details_properties-page {
  margin: 0 30px;
}
.contact-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/images/hero_bg_2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 300px;
}
.property-posted-details-page{
 
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  margin-top: 10px;
  width: fit-content;
  font-style: italic;
  text-align: left;
}

.contact-container-title h2 {
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  position: relative;
  z-index: 3;
}

.contact-container-title-overlay {
  background-color: rgb(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.property-info-container {
  background-color: #fafafa;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
}
.property-details-wrapper {
  display: flex;
  justify-content: center;
}
.property-main {
  width: 55%;
}
.property-agent {
  width: 40%;
}
.property-images {
  margin: 15px;
  position: relative;
  background-color: #fafafa;
  border: 1px solid #ddd;
  padding: 10px;
}

.main-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.image-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.image-navigation button {
  background-color: #cc9900;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}
.thumbnail-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.thumbnail-images img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.7;
  border: 2px solid transparent;
  transition: opacity 0.3s, border-color 0.3s;
}
.thumbnail-images img.active {
  opacity: 1;
  border-color: #cc9900;
}
.property-address,
.property-price {
  text-align: left;
}
.property-info {
  flex: 2;
}
.property-details-row--container {
  display: flex;

  color: #cc9900;
}
.property-details-row--container i {
  color: #cc9900;
  font-size: 22px;
}
.property-description .accordion-button {
  background-color: #bdbdbd;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 18px;
  margin: 10px 0 10px 0;
}

.property-description .accordion-content {
  background-color: #fafafa;
  padding: 10px;
  border: 1px solid #ddd;
}
.property-description .accordion-content p {
  text-align: left;
}
.property-otherdetails-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  background-color: #fafafa;
}

.property-details-column {
  display: flex;
  gap: 20px;
}

.property-details-row {
  width: 100%;
}

.property-details-row p {
  text-align: left;
}

.property-info-agent {
  flex: 2;
}

.contact-agent-wrapper {
  position: relative;
  margin: 12px 40px 0 0 !important;
}

.contact-agent {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin-top: 16px;
  border: 1px solid #ddd;
}

.contact-agent.stop-sticky {
  position: absolute;
  bottom: 0;
  top: unset;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  border: 1px solid #ddd;
}

.contact-form label {
  margin: 10px 0 5px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.contact-form button {
  background-color: #cc9900;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.related-section {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.related-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.related-properties {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.related-property-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.related-property-card:hover {
  cursor: pointer;
  background-color: #ffda6b;
}
.related-property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.related-property-info {
  padding: 15px;
}

.related-property-info h3 {
  text-align: center;
  font-size: 1.2rem;
  color: red;
  margin-bottom: 10px;
}

.related-property-info p {
  text-align: left;
  margin: 5px 0;
}

.property-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.property-details span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .property-details-wrapper {
    flex-direction: column;
  }
  .property-images {
    margin: 0;
  }
  .property-main {
    width: 100%;
  }

  .property-agent {
    width: 100%;
  }
  .contact-agent {
    position: static;
    box-shadow: none;
  }

  .contact-form {
    position: static;
  }

  .property-details {
    gap: 10px;
  }
  .property-details-row--container {
    display: flex;
    flex-direction: row;
    color: #cc9900;
  }
  .thumbnail-images {
    flex-wrap: wrap;
    gap: 5px;
  }

  .thumbnail-images img {
    width: 70px;
    height: 70px;
  }

  .property-location {
    width: 100%;
  }
}
