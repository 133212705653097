.services-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: 20px;
  gap: 20px;
  background-color: #f9f9f9; /* Light background color */
}

.service-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
width: 100%;
  text-align: center;
}

.service-icon {
  font-size: 48px;
  color: #f9a825; /* Color similar to the icons in your screenshot */
  margin-bottom: 15px;
}

.service-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.service-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
@media (max-width: 992px) {
  .services-container {
    flex-direction: column;
  }
}
