
.featured-properties {
  background: #fff;
  padding: 25px 0;
}

.featured-properties h2 {
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
}

.property-carousel {
  margin: 20px;
}

.property-card {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.featuredProperty-page-card {
  text-decoration: none;
}

.property-card:hover {
  cursor: pointer;
  background-color: rgba(255, 223, 40, 0.589);
  transform: translateY(-10px);
}

.property-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.property-info {
  color: #5c5c5c;
  padding: 15px;
}

.property-type {
  font-weight: bold;
}

.property-type.featured {
  color: red;
}

.property-price {
  display: block;
  font-size: 1.2em;
  margin: 10px 0;
}

.property-price-detail {
  display: block;
  font-size: 0.9em;
  color: #777;
  text-align: left;
}

.property-name {
  margin: 10px 0 5px;
  text-align: left !important;
}
.property-page-name{
  text-align: left;
}
.property-address {
  font-size: 0.9em;
  color: #555;
}

.property-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.property-detail {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
}
.property-image-container {
  position: relative;
}

.property-category {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
}

.property-posted {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  font-style: italic;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .featured-properties {
    margin: 0;
  }
  .featured-properties h2 {
    font-size: 1.5rem;
  }
  .property-card img {
    height: 150px;
  }

  .property-info {
    margin-top: 5px;
  }
}
