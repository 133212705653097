.search-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
}

.search-form-container {
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}

.search-form input,
.search-form select,
.search-form button {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  height: fit-content !important;
}
.search-form .input-section{
  width: 100% !important; /* Use 100% to match the container width */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-form .input-section:hover {
cursor: pointer;
color: #cc9900;
}

.search-form button {
  background-color: #cc9900;
  color: #fff;
  cursor: pointer;
  border: none;
}

.search-form button:hover {
  background-color: #b58600;
}

.dropdown {
  position: relative;
}

.dropdown__value {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.dropdown__options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  display: none;
}

.dropdown__option {
  padding: 10px;
  cursor: pointer;
}

.dropdown:hover .dropdown__options {
  display: block;
}

.dropdown__option:hover {
  background-color: #f0f0f0;
}

.switcher {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switcher__control {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.switcher__control::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.switcher__control.active::before {
  transform: translateX(20px);
  background-color: #4caf50;
}

.switcher__label {
  font-size: 1rem;
}

.show-more {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.show-more:hover {
  background-color: #bbb;
}

.dropdown__value-text,
.dropdown__option,
.switcher__label {
  color: black;
}

.more-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* Dropdown container */
.dropdown {
  position: relative;
  width: 100%;
}

/* Dropdown options */
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  border-radius: 5px;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}
@media (max-width: 768px) {
  .search-form {
    flex-direction: column;
    padding: 15px;
  }

  .search-form-container {
    flex-direction: column;
    gap: 15px;
  }

  .search-form input, .search-form select, .search-form button {
    width: 100%; /* Make all elements full width */
  }

  .dropdown-options {
    width: 100%; /* Ensure the dropdown takes up the full width of the container */
  }

  .search-button {
    width: 100%; /* Make the search button full width */
    padding: 12px; /* Increase padding for better tap areas */
  }
}

@media (max-width: 480px) {
  .search-form {
    padding: 10px;
  }

  .search-form input, .search-form select, .search-form button {
    font-size: 0.9rem;
  }
}

