
.category-section {
  text-align: center;
  padding: 20px;
  background: #f9f9f9;
}

.category-section-heaher {
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 0 20px;
}

.categories {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  gap: 20px;
}

.category {
  text-align: center;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: #eee 1px solid;
  background: #fff;
}

.category-icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 10px;
  background-color: rgb(65, 65, 65, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-icon i {
  font-size: 60px;
  color: #f9a825;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

p {
  font-size: 0.9rem;
  color: #666;
}
@media (max-width: 768px) {
  .categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
  }
  .category {
    text-align: center;
    max-width: 100%;
    background-color: rgb(128, 128, 128, 0.5);
    padding: 20px;
  }
}
