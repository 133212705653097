
.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: #fff;
}
.contact-left,
.contact-right {
  flex: 1;
}

.contact-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-left h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-left p {
  margin-bottom: 20px;
  color: #666;
  text-align: left;
}

.contact-info {
  margin-top: 20px;
}

.contact-info-item {
  display: flex;
  margin-bottom: 10px;
}

.contact-info-item i {
  font-size: 1.5em;
  margin-right: 10px;
  color: #f9a825;
}

.contact-info-item p {
  text-align: left;
  margin: 0;
}

.contact-right {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
}

.contact-right h2 {
  margin-bottom: 10px;
}

.contact-right p {
  margin-bottom: 20px;
  color: #666;
}
.form-group-row {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  width: 100%;
}
.form-group {
  margin-bottom: 15px;
  width: 100%;
}
.form-group-column {
  display: flex;
  flex-direction: column;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-with-icon {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
}

.input-with-icon input,
.input-with-icon textarea {
  flex: 1;
  border: none;
  outline: none;
}

.input-with-icon svg {
  margin-right: 10px;
  color: #f9a825;
}

button {
  background-color: #f9a825;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #d48806;
}
@media (max-width: 768px) {
  .contact-container-title h2 {
    font-size: 30px !important;
  }
  .contact-left h1 {
    font-size: 1.4em;
    margin-bottom: 20px;
    text-align: center;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .form-container {
    width: 100%;
  }
  .form-group-row {
    flex-direction: column;
  }
}
