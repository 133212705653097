.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/images/hero_bg_2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 80vh;
  z-index: 1;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.hero-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 50px;
  font-style: normal;
  z-index: 3;
  color: #fff;
  text-transform: uppercase;
}

.hero-search {
  background-color: #fff;
  z-index: 4;
}

@media (max-width: 992px) {
  .hero-title {
    font-size: 44px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 28px;
  }
}
